<template>
    <div class="home-route">
        <div class="welcome-header">
            <b-img :src="branding.secondaryLogoUrl" class="logo" />
            <p class="header-first-text">{{ $t("homeRoute.headerFirstText") }}</p>
            <p class="header-second-text">{{ $t("homeRoute.headerSecondText") }}</p>
        </div>
        <div v-if="missionsLoading">
            <div class="text-center loading-text w-100 mt-4">
                {{ $t("homeRoute.loadingData") }}
            </div>
            <div class="loader-container">
                <loader />
            </div>
        </div>
        <div v-else class="carousel-outer-container">
            <slider
                ref="missionCarousel"
                class="mission-carousel"
                :options="carouselOptions"
                @slide="slideChanged"
            >
                <slideritem
                    v-for="mission in displayableMissions"
                    :key="mission.id"
                    class="mission-slide"
                >
                    <mission :mission="mission" />
                </slideritem>
            </slider>
            <div v-if="showPrevArrow" class="arrow left" @click="prevSlide">
                <font-awesome-icon :icon="['fas', 'chevron-left']" />
            </div>

            <div v-if="showNextArrow" class="arrow right" @click="nextSlide">
                <font-awesome-icon :icon="['fas', 'chevron-right']" />
            </div>
        </div>
        <div v-if="!missionsLoading && displayableMissions.length === 0">
            <div class="text-center loading-text w-100 mt-4">
                {{ $t("homeRoute.noMissionsYet") }}
            </div>
        </div>
        <div
            v-if="homeBanners && homeBanners.length > 0"
            class="info-message-container"
        >
            <banner-slide-show
                v-if="homeBanners && homeBanners.length > 0"
                :slides="homeBanners"
                :slide-interval="10000"
            />
        </div>
    </div>
</template>

<script>
    import Mission from '@/components/Mission'
    import { slider, slideritem } from 'vue-concise-slider'
    import Loader from '@/components/Loader'

    import BannerSlideShow from '@/components/BannerSlideShow'
    import commercialBannerService from '@/services/commercial-banner-service'
    export default {
        components: {
            Mission,
            Loader,
            slider,
            slideritem,
            BannerSlideShow
        },

        data() {
            return {
                carouselIndex: 0

            }
        },
        computed: {
            missions() {
                return this.$store.getters.getMissions
            },
            displayableMissions() {
                return this.missions.filter(mission => {
                    return !mission.locked ||
                        (mission.locked && new Date(mission.startDate) >= new Date())
                })
            },
            missionsLoading() {
                return this.$store.getters.getMissionsLoading
            },
            homeBanners() {
                return this.$store.getters.getHomeBanners
            },
            branding() {
                return this.$store.getters.getBranding
            },
            hasBelowTwoMissions() {
                return this.displayableMissions && this.displayableMissions.length < 2
            },
            showPrevArrow() {
                return (this.carouselIndex > 0) && !this.hasBelowTwoMissions
            },
            showNextArrow() {
                return (this.carouselIndex < this.displayableMissions.length - 1) && !this.hasBelowTwoMissions
            },
            carouselOptions() {
                return {
                    pagination: false,
                    centeredSlides: this.hasBelowTwoMissions
                }
            }
        },
        created() {
            this.initCommercialBanners()
        },
        methods: {
            initCommercialBanners() {
                commercialBannerService.getCommercialBanners().then((banners) => {
                    this.$store.commit('setCommercialBanners', banners.data)
                })
            },
            prevSlide() {
                this.$refs.missionCarousel.$emit('slidePre')
            },
            nextSlide() {
                this.$refs.missionCarousel.$emit('slideNext')
            },
            slideChanged(data) {
                this.carouselIndex = data.currentPage
            }

        }
    }
</script>

<style lang="scss" scoped>
.home-route {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    justify-content: center;
    padding: 1rem 0 2.5rem;
    background-image: var(--landing-bg-image-url);
    background-size: cover;
}

.mission {
    outline: none;
    margin: 0 auto;
}

.loading-text {
    color: var(--main-content-text);
}

.welcome-header {
    margin-bottom: 1rem;
    padding-left: 15px;
    padding-right: 15px;
}

.header-first-text {
    font-size: 1.5rem;
    color: var(--home-title-color);
}

.header-second-text {
    font-size: 1rem;
    color: var(--home-subtitle-color);
}

.loader-container {
    width: 200px;
    height: 200px;
    margin: 0 auto;
}

.logo {
    width: 120px;
    margin: 0 auto;
}

.mission-carousel {
    margin-top: 1rem;
}

.carousel-outer-container {
    position: relative;
    padding: 0 2rem;
}

.arrow {
    display: block;
    color: var(--logo-highlight-color);
    cursor: pointer;
    position: absolute;
    top: 50%;
    padding: 0 0.5rem;
}

.arrow.left {
    left: 0;
}

.arrow.right {
    right: 0;
}

.slider-container {
    white-space: normal;
    height: unset;
}

.slider-item {
    height: unset;
}

.mission-slide {
    display: grid;
    place-items: center;
    margin-right: 1.5rem;
    width: 100%;
    max-width: 360px;
    min-height: 300px;
}

.carousel-pagination {
    padding: 1.5rem 0;
    min-height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bullet {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--light-highlight-color);
    cursor: pointer;
}

.bullet + .bullet {
    margin-left: 1rem;
}

.bullet.active {
    background: var(--logo-highlight-color);
}

.info-message-container {
    margin: 3rem auto 0;
    max-width: 1000px;
}

.info-message-container img {
    width: 95%;
}

@media screen and (min-width: 768px) {
    .header-first-text {
        font-size: 2.25rem;
    }

    .mission-slide {
        max-width: 330px;
        min-height: 300px;
        margin-right: 2rem;
    }

    .logo {
        width: 200px;
    }

    .mission-carousel {
        margin-top: 2rem;
    }

    .carousel-outer-container {
        padding: 0 2.25rem;
    }

    .info-message-container {
        margin: 4rem auto 0;
    }
}

@media screen and (min-width: 992px) {
    .home-route {
        justify-content: flex-start;
        padding: 1rem 0;
    }

    .mission-slide {
        width: 100%;
        max-width: 420px;
        margin-right: 2.5rem;
    }

    .info-message-container {
        margin: 0 auto;
    }
}
</style>
