<template>
    <div v-if="slides.length > 0" class="slideshow-container">
        <div
            v-for="(slide, index) in localSlides"
            :key="slide.id"
            class="slide-container fade"
            :class="{'is-url': slide.url}"
            @click="slideClicked(slide)"
        >
            <img class="slide-image" :src="slide.contentUrl" :alt="`${slide.position} ${index + 1}`">
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            slides: {
                type: Array,
                required: true
            },
            slideInterval: {
                type: Number,
                default: 5000
            }
        },
        data() {
            return {
                currentSlide: 0,
                localSlides: []
            }
        },
        beforeMount() {
            this.localSlides = this.slides
            this.localSlides.sort((a, b) => a.sort - b.sort)
        },
        mounted() {
            this.startSlideShow()
        },
        methods: {
            startSlideShow() {
                let i
                let slides = document.getElementsByClassName('slide-container')
                if (!slides || slides.length === 0) {
                    return
                }
                for (i = 0; i < slides.length; i++) {
                    slides[i].style.display = 'none'
                }
                this.currentSlide++
                if (this.currentSlide > slides.length) {
                    this.currentSlide = 1
                }
                slides[this.currentSlide - 1].style.display = 'block'
                if (this.slides.length > 1) {
                    setTimeout(this.startSlideShow, this.slideInterval)
                }
            },
            slideClicked(slide) {
                if (slide?.url) {
                    window.open(slide.url, '_blank')
                }
            }
        }
    }
</script>

<style scoped>
.slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: 3rem auto 0;
    overflow: hidden;
}

.slide-container {
    display: none;
}

.is-url {
    cursor: pointer;
}

.slide-count {
    color: #f2f2f2;
    font-size: 0.75rem;
    padding: 0.15rem 0.25rem;
    position: absolute;
    top: 0;
}

.slide-image {
    width: 95%;
    height: auto;
}

.fade {
    animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

</style>
