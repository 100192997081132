<template>
    <div
        class="mission"
    >
        <div
            class="mission-header"
        >
            <img
                :src="`${mission.imageUrl}`"
                alt="mission image"
                class="mission-image"
                data-testid="mission-image"
            >
            <div class="mission-status">
                <span
                    v-if="mission.completed"
                    class="span-completed"
                    data-testid="status-completed"
                >
                    <font-awesome-icon :icon="[ 'far', 'check-circle' ]" class="mr-2" /> {{
                        $t('missionTile.completed')
                    }} !
                </span>
                <span
                    v-else-if="mission.locked"
                    class="span-locked"
                    data-testid="status-locked"
                >
                    {{ $t('missionTile.locked') }}
                </span>
            </div>
        </div>
        <div class="mission-info">
            <div class="tasks-count">
                {{ mission.tasks.length }} {{ mission.tasks.length === 1 ? $t('missionTile.task') : $t('missionTile.tasks') }}
            </div>
            <div class="title-play-container">
                <div
                    class="mission-title line-clamp"
                    data-testid="mission-title"
                >
                    {{ mission.alias }}
                </div>
                <button
                    :class="{ 'disabled': isPlayDisabled }"
                    :disabled="isPlayDisabled"
                    class="play-btn"
                    data-testid="play-button"
                    type="button"
                    @click="goToTasks(mission)"
                > {{ $t('missionTile.pressHereToPlay') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Mission',
        props: {
            mission: {
                type: Object,
                required: true
            }
        },
        computed: {
            isPlayDisabled() {
                return this.mission.locked || (this.mission.completed && !this.isPlayAgainEnabled) || this.isExcludedFromPlayAgain
            },
            isPlayAgainEnabled() {
                return this.$store.getters.isPlayAgainEnabled
            },
            skipMissionRouteOnSingleTask() {
                return this.$store.getters.skipMissionRouteOnSingleTask
            },
            nextTaskPath() {
                return this.$store.getters.getNextTaskPath(this.mission.id)
            },
            isExcludedFromPlayAgain() {
                return this.mission.completed && this.mission.tasks.length === 1 &&
                    (['ACTION_ARENA', 'ACTION_LIBRARY', 'ACTION_SURVEY', 'ACTION_PROFILE', 'ACTION_BOARDING_SCAN'].includes(this.mission.tasks[0].taskType.alias))
            }
        },
        methods: {
            goToTasks(mission) {
                if (this.isPlayDisabled || mission.tasks.length === 0) {
                    return
                }
                if (this.skipMissionRouteOnSingleTask && mission.tasks.length === 1) {
                    this.$router.push(this.nextTaskPath)
                } else {
                    this.$router.push(`/missions/${mission.id}`)
                }
            }
        }
    }
</script>

<style scoped>
.mission {
    border-radius: 10px;
    height: 100%;
    width: 100%;
    padding: 0.75rem;
    background-color: var(--mission-background-color);
    display: flex !important;
    flex-direction: column;
    gap: 0.5rem;
}

.mission-header {
    position: relative;
}

.mission-image {
    width: 100%;
    height: 190px;
    object-fit: contain;
}

.mission-status {
    padding-right: 0;
}

.mission-status > .span-locked {
    position: absolute;
    top: 5%;
    right: 0;
    display: block;
    background: var(--error);
    padding: 8px;
    font-weight: 700;
    font-size: 0.8rem;
    text-align: center;
    color: var(--white);
    box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
    border-radius: 10px 0 0 10px;
}

.mission-status > .span-completed {
    position: absolute;
    top: 5%;
    right: 0;
    display: block;
    background: var(--secondary-color);
    padding: 8px;
    font-weight: 700;
    font-size: 0.8rem;
    text-align: center;
    color: var(--white);
    box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
    border-radius: 10px 0 0 10px;
}

.mission-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
}

.tasks-count {
    color: var(--mission-title-color);
    font-size: 14px;
    font-weight: 700;
    text-size-adjust: 100%;
}

.title-play-container {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    flex-direction: column;
}

.mission-title {
    color: var(--mission-subtitle-color);
    font-size: 1rem;
    text-align: left;
}

.play-btn {
    background: var(--mission-button-color);
    color: var(--mission-button-text-color);
    font-size: 1.25rem;
    font-weight: 700;
    text-size-adjust: 100%;
    min-width: 140px;
    height: 47px;
    box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
    border-radius: 6px;
    border: 0;
    display: grid;
    place-items: center;
    cursor: pointer;
    transition: all 0.1s ease-in;
}

.play-btn:active:enabled {
    transform: scale(0.9);
}

.disabled {
    background: var(--disabled-color);
    pointer-events: none;
}

.play-btn.disabled:active {
    transform: none;
}

/* stylelint-disable */

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* stylelint-enable */

@media screen and (min-width: 768px) {
    .mission-image {
        width: 100%;
        height: 200px;
        object-fit: contain;
    }

    .title-play-container {
        flex-direction: row;
    }

    .mission-status > .span-locked {
        position: absolute;
        top: 5%;
        right: 0;
        display: block;
        background: var(--error);
        padding: 8px;
        font-weight: 700;
        font-size: 1rem;
        text-align: center;
        color: var(--white);
        box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
        border-radius: 10px 0 0 10px;
    }

    .mission-status > .span-completed {
        position: absolute;
        top: 5%;
        right: 0;
        display: block;
        background: var(--secondary-color);
        padding: 8px;
        font-weight: 700;
        font-size: 1rem;
        text-align: center;
        color: var(--white);
        box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
        border-radius: 10px 0 0 10px;
    }
}

@media screen and (min-width: 992px) {
    .mission-image {
        height: 245px;
    }

    .mission-status > .span-locked,
    .mission-status > .span-completed {
        font-size: 1.2rem;
    }
}

</style>
